import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout/layout';
import {Link} from 'gatsby'
import Img from 'gatsby-image'
export default function Blog({ data }) {
  const { posts } = data.blog

  return (
    <Layout>
    <div className="blog">
      <div className="container">
      {posts.map(post => (
        <article key={post.id}>
         <div className="blog-item">
            <Link to={post.fields.slug}>
            <div className="blog-item__img">
            <Img fluid={post.frontmatter.image.childImageSharp.fluid}/>
            </div>
            </Link>
            <div className="blog-item__title">
            <h2>{post.frontmatter.title}</h2>
            </div>
            <div className="blog-item__info">
            <span>{post.frontmatter.date}</span>
            <span>autor: {post.frontmatter.author}</span>
            </div>
            <div className="blog-item__link">
            <Link className="primary-btn" to={post.fields.slug}>Czytaj więcej..</Link>
            </div>
          </div>
        </article>
      ))}
      </div>
    </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/blog/" } },
         sort: {fields: [frontmatter___date], order: DESC}
       )  {
      posts: nodes {
        frontmatter {
          date(formatString:"YYYY-MM-DD")
          title
          author
          image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
              resize(width: 900, quality: 90) {
                src
              }
            }
          }
        }
        fields{
          slug
        }
        excerpt
        id
      }
    }
  }
`